import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

const Navbar = () => {
  const progressRef = React.useRef(null);

  React.useEffect(() => {
    if (progressRef.current !== null) {
      const eventListener = () => {
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
        const scrollRatio = scrollTop / (scrollHeight - document.documentElement.clientHeight);
        progressRef.current.style.setProperty('--scroll', `${scrollRatio * 100}%`);
      };

      document.addEventListener('scroll', eventListener);

      return () => {
        document.removeEventListener('scroll', eventListener);
      }
    }

    // Javascript to toggle the menu
    // document.getElementById('nav-toggle').onclick = () => {
    //   document.getElementById('nav-content').classList.toggle('hidden');
    // };
  }, []);

  return (
    <nav id="header" className="fixed w-full h-16 z-10 top-0 bg-white shadow">
      <div ref={progressRef} className="h-1 z-20 top-0" style={{ transition: 'background 1s', background: 'linear-gradient(to right, #4dc0b5 var(--scroll), transparent 0)' }} />

      <div className="container w-full max-w-5xl px-8 mx-auto flex flex-wrap items-center justify-between py-3">
        <Link to="/">
          <a className="flex items-center text-gray-900 no-underline hover:no-underline font-extrabold text-2xl">
            <StaticImage
              className="rounded"
              alt=""
              src="../images/mugshot-small.jpg"
              width={32}
              height={32}
            />

            <span className="pl-2 font-semibold">
              William
            </span>

            <span className="pl-1 font-extralight">
              Di&nbsp;Luigi
            </span>
          </a>
        </Link>

        {/* <div className="block md:hidden pr-4">
          <button type="button" id="nav-toggle" className="flex items-center px-3 py-2 border rounded text-gray-500 border-gray-600 hover:text-gray-900 hover:border-green-500 appearance-none focus:outline-none">
            <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div> */}

        <div className="flex flex-grow" id="nav-content">
          <ul className="list-reset flex justify-end flex-1">
            <li>
              <Link to="/projects" activeClassName="font-bold text-gray-900">
                <a className="py-2 px-4 no-underline text-gray-600 hover:text-gray-900">Projects</a>
              </Link>
            </li>

            {/* <li className="mr-3">
              <Link to="/about" activeClassName="font-bold text-gray-900">
                <a className="inline-block py-2 px-4 no-underline text-gray-600 hover:text-gray-900">About me</a>
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;