import React from 'react';
import { Helmet } from "react-helmet";
import favicon from '../images/mugshot-small.jpg';

const SEO = ({ title, description }) => (
  <Helmet>
    <title>{title}</title>
    <meta name="description" content={description} />
    <meta name="keywords" content="Software Engineer,JavaScript,TypeScript,React,C++,Python,Competitive Programming,Olympiads in Informatics"/>
    <link rel="icon" type="image/png" href={favicon} />
  </Helmet>
)

export default SEO