import React from 'react';
import { FaLinkedinIn, FaGithubAlt, FaTelegramPlane, FaMastodon } from 'react-icons/fa';

const Footer = () => (
  <footer className="bg-gray-50 border-t">
    <div className="container max-w-5xl mx-auto flex py-8">
      <div className="w-full mx-auto flex flex-wrap">
        <div className="flex w-full md:w-1/2 ">
          <div className="px-8">
            <h3 className="font-bold text-gray-900">About</h3>
            <p className="py-4 text-gray-600 text-sm">
              I&rsquo;m a Software Engineer, in my free time I like
              cooking&nbsp;<span role="img" aria-label="Pizza Slice">🍕</span> playing piano&nbsp;🎹 and creating programming challenges&nbsp;👨‍💻

              <span className="block mt-2">I&rsquo;m currently living in Zurich&nbsp;<span role="img" aria-label="Swiss Flag">🇨🇭</span></span>
            </p>
          </div>
        </div>

        <div className="flex w-full md:w-1/2">
          <div className="px-8">
            <h3 className="font-bold text-gray-900">Where to find me</h3>
            <ul className="list-reset items-center text-sm pt-3">
              <li>
                <a className="flex items-center text-gray-600 no-underline hover:text-gray-900 hover:text-underline py-1" href="https://www.linkedin.com/in/williamdiluigi/">
                  <FaLinkedinIn size={16} />
                  <span className="ml-2">williamdiluigi</span>
                </a>
              </li>
              <li>
                <a className="flex items-center text-gray-600 no-underline hover:text-gray-900 hover:text-underline py-1" href="https://github.com/wil93">
                  <FaGithubAlt size={16} />
                  <span className="ml-2">wil93</span>
                </a>
              </li>
              <li>
                <a className="flex items-center text-gray-600 no-underline hover:text-gray-900 hover:text-underline py-1" href="http://t.me/wil93">
                  <FaTelegramPlane size={16} />
                  <span className="ml-2">wil93</span>
                </a>
              </li>
              <li className="hidden">
                <a rel="me" className="flex items-center text-gray-600 no-underline hover:text-gray-900 hover:text-underline py-1" href="https://mastodon.social/@wdl">
                  <FaMastodon size={16} />
                  <span className="ml-2">wdl</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer
